<template>
    <div class="monitor-2 flex flex-direction pt-30">
        <div class="mt-20 align-items">
            <MyTitle class="gj-titile flexs" title="监控告警" />
            <div class="flex flex1 target-box align-items">
                <div
                    v-for="(item, i) in target1"
                    :key="'target1' + i"
                    class="flex align-center justify-center target flex1">
                    <span class="img-box" :class="{'box-at': item.number != 0 }">
                        <img v-if="item.number != 0" class="img" :src="item.iconAt" />
                        <img v-else class="img" :src="item.icon" />
                    </span>
                    <div class="flex flex1 flex-direction text-align">
                        <div class="label">{{ item.label}}</div>
                        <div class="text" :style="{'color': item.number != 0 ? '#FF4348' : ''}">{{ item.number || 0 }}</div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- <div class="flex flex1 video-box flex-wrap justify-content-between mt-30"> -->
        <div class="grid flex1 video-box">
            <div
                v-for="(item, index) in videoList"
                :key="'videoList' + index"
                class="video-item">
                <video
                    :ref="`my-player${index}`"
                    :playUrl="item.url"
                    class="video video-js"
                    muted>
                </video>
                <MyTitle :title="item.stationName" type="2" />
            </div>
        </div>
        <!-- <Human /> -->
    </div>
</template>

<script>
import MyTitle from '@/views/components/MyTitle'
// import Human from '@/views/components/Human'
import { dataInterface } from '@/api/dataInterfaceApi'
export default {
    components: {
        MyTitle,
        // Human
    },
    data() {
        let { stationId } = this.$route.query || {}
        return {
            stationId: stationId,
            target1: [
                {
                    icon: require('@/assets/images/myComm/gj-1.png'),
                    iconAt: require('@/assets/images/myComm/gj-1-at.png'),
                    number: '',
                    label: '消防系统'
                },
                {
                    icon: require('@/assets/images/myComm/gj-2.png'),
                    iconAt: require('@/assets/images/myComm/gj-2-at.png'),
                    number: '',
                    label: '电池系统'
                },
                {
                    icon: require('@/assets/images/myComm/gj-3.png'),
                    iconAt: require('@/assets/images/myComm/gj-3-at.png'),
                    number: '',
                    label: '换电系统'
                },
                {
                    icon: require('@/assets/images/myComm/gj-4.png'),
                    iconAt: require('@/assets/images/myComm/gj-4-at.png'),
                    number: '',
                    label: '站控系统'
                },
                {
                    icon: require('@/assets/images/myComm/gj-5.png'),
                    iconAt: require('@/assets/images/myComm/gj-5-at.png'),
                    number: '',
                    label: '充电系统'
                },
            ],
            videoObj:{},
            videoList: [], // Array.from({length: 9},()=> new Object()),
			listQuery: {
				currentPage: 1,
				pageSize: 9,
				sort: 'desc'
			},
            // monArr: Array.from({length: 9},()=> new Object()),
            // allvideos: [],
			videopage: 0,
            videoInterval: null, // 定时器
        }
    },
    mounted() {
        // 实时告警
        this.gaojing()
        // 获取站点监控视频
        this.getDeviceList()
    },
    methods: {

        // 实时告警
        gaojing() {
            let { province } = this.$route.query
            let params = {
                "enCode": !province ? "TiDB-quanguo-shishigaojing" : 'TiDB-shengshiqu-shishigaojing',
            }
            province ? params['@province'] = province : ''
            dataInterface.dataFaceApi(params).then((res) => {
                console.log('=========', res)
                if(!res || !res.length) return
                // let alarmTimesNum = res[0] && res[0].alarmTimesNum || []
                res.map((item, i) => {
                    this.target1[i].number = item.alarmTimesNum
                })
            })
            .catch((err) => {
                console.log(err)
            })
        },


        // // 获取站点监控视频
        // getDeviceList() {
		// 	this.clearVideojs();
		// 	var params = {
		// 		stationId: this.stationId,
		// 		// regionId: code,
		// 		pageSize: this.listQuery.pageSize,
		// 		currentPage: this.listQuery.currentPage
		// 	}
		// 	dataInterface.carouselList(params).then(async (res) => {
		// 		// this.videoObj = Object.assign({}, {});
		// 		if (!res || !res.length) return;
		// 		// debugger
		// 		console.log('查询到的直播流数据111', res)
		// 		// this.total = res.data.total
		// 		let monArr = []
		// 		res.map((item, x) => {
		// 			monArr.push(item)
		// 			if (((x + 1) % 4) === 0) {
		// 				this.allvideos.push(monArr)
		// 				monArr = []
		// 			}
		// 		})
		// 		console.log('this.allvideos ===========', this.allvideos)
		// 		this.setVideo()
		// 		this.videoInterval = setInterval(() => {
		// 			this.videopage++
		// 			// console.log('this.videopage==================', this.videopage)
		// 			this.setVideo()
		// 		}, 30000)
		// 	}).catch((err) => {
		// 		console.log(err);
		// 	});
		// },
        // // 通过code 获取 视频地址
		// async setVideo() {
		// 	if (this.videopage >= this.allvideos.length) {
		// 		this.videopage = 0
		// 	}
		// 	let monArr = this.allvideos[this.videopage] || []
		// 	console.log('monArr ===========', monArr)
		// 	this.monArr = []
		// 	for (let i = 0; i < monArr.length; i++) {
		// 		if (monArr[i].deviceCode != null) {
		// 			let url = await dataInterface.selectUrlByCode({
		// 				deviceCode: monArr[i].deviceCode
		// 			})
		// 			monArr[i].url = url?.url
		// 		}
		// 	}

		// 	this.monArr = monArr
		// 	this.$nextTick(() => {
		// 		this.monArr.forEach((v, i) => {
		// 			if (v.url) {
		// 				const playerDom = this.$refs[`my-player${i}`][0]
		// 				Object.assign(this.videoObj, {
		// 					[`video${i}`]: videojs(
		// 						playerDom, {
		// 							autoplay: true, // 设置自动播放
		// 							controls: true, // 显示播放的控件
		// 							preload: 'metadata',
		// 							aspectRatio: '16:9',
		// 							fluid: true,
		// 							sources: [
		// 								// 注意，如果是以option方式设置的src,是不能实现 换台的 (即使监听了nowPlayVideoUrl也没实现)
		// 								{
		// 									src: playerDom.attributes.playUrl
		// 										.nodeValue,
		// 									type: 'application/x-mpegURL', // 告诉videojs,这是一个hls流
		// 								},
		// 							],
		// 						},
		// 						function() {},
		// 					),
		// 				})
		// 			}
		// 		})
		// 	})
		// },
		// 清除videojs
		clearVideojs() {
			let videoObjTemp = Object.keys(this.videoObj);
			if (videoObjTemp && videoObjTemp.length) {
				videoObjTemp.forEach(v => {
					if (this.videoObj[v]) {
						this.videoObj[v].dispose()
					}
				})
			}
		},
        // 获取站点监控视频
        getDeviceList() {
            let { province, city, district } = this.$route.query
            this.clearVideojs();
			var params = {
				stationId: this.stationId ? this.stationId : '2e41596bffe24387acd479709999077f',
                limit: 5,
				regionId: (province && province.slice(0, 2) || '') || (city && city.slice(0, 4) || '') || district
			}
			dataInterface.carouselList(params).then(async (res) => {
				// this.videoObj = Object.assign({}, {});
				if (!res || !res.length) return;
                let resData = res.splice(0,9)
                // this.videoList = resData.map(async (item) => {
                //     if (item.deviceCode != null) {
                //         let url = await dataInterface.selectUrlByCode({
                //             deviceCode: item.deviceCode
                //         })
                //         item.url = url?.url
                //     }
                // })
                for (let i = 0; i < resData.length; i++) {
                    if (resData[i].deviceCode != null) {
                        let url = await dataInterface.selectUrlByCode({
                            deviceCode: resData[i].deviceCode
                        })
                        resData[i].url = url?.url
                    }
                }
                this.videoList = resData.splice(0,4)
                console.log('this.videoList', this.videoList)
                this.$nextTick(() => {
                    this.videoList.forEach((v, i) => {
                        if (v.url) {
                            const playerDom = this.$refs[`my-player${i}`][0]
                            Object.assign(this.videoObj, {
                                [`video${i}`]: videojs(
                                    playerDom,
                                    {
                                        autoplay: true, // 设置自动播放
                                        controls: true, // 显示播放的控件
                                        preload: 'metadata',
                                        aspectRatio: '16:8',
                                        fluid: true,
                                        sources: [
                                            // 注意，如果是以option方式设置的src,是不能实现 换台的 (即使监听了nowPlayVideoUrl也没实现)
                                            {
                                                src: playerDom.attributes.playUrl.nodeValue,
                                                type: 'application/x-mpegURL', // 告诉videojs,这是一个hls流
                                            },
                                        ],
                                    },
                                    function () {
                                    },
                                ),
                            })
                        }
                    })
                })
            })
        }
    },
    destroyed() {
        this.clearVideojs()
    }
}
</script>

<style lang="scss" scoped>
.monitor-2 {
    width: 1770px;
    padding: 0 120px;
    color: #fff;
    .gj-titile {
        width: 350px;
    }
    .target-box {
        height: 200px;
        .target {
            padding-right: 70px;
            box-sizing: border-box;
            .img-box {
                width: 140px;
                height: 114px;
                background: url('~@/assets/images/myComm/gj.png') center bottom no-repeat;
                background-size: 100%;
                &.box-at {
                    background: url('~@/assets/images/myComm/gj-at.png') center bottom no-repeat;
                    background-size: 100%;
                }
                .img {
                    width: 50px;
                    display: block;
                    margin: -10px auto 0;
                }
            }
            .label {
                font-size: $FontSize24;
                white-space: nowrap;
                color: #fff;
            }
            .text {
                font-size: $FontSize48;
                font-weight: bold;
                @include ffamily-DS;
            }
        }
    }
    .video-box {
        margin-top: 25px;  // 4路
        width: 100%;
        // grid-template-columns: 33.33% 33.33% 33.33%;  //9路
        // grid-template-rows: 33.33% 33.33% 33.33%;

         grid-template-columns:  50% 50%;  //  4路
        grid-template-rows: 50% 50%;
        // padding-right: 160px;
        .video-item {
            width: 995px;
            height: 30px;
            padding: 0 50px;  //  9路是注释的
            box-sizing: border-box;
            video{
                object-fit:fill;
                width:100%;
                height:100%;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 50px;
            }
            .video {
                // width: 540px; // 9路
                // height: 304px;
                width: 700px;
                height: 280px;
            }
        }
    }
}
</style>
<style>
	.vjs-loading-spinner{
		opacity: 0;
	}
</style>