<template>
    <div class="monitor-1 flex flex-direction">
        <div class="video-top mt-80">
            <video class="video" muted autoplay loop src="https://sass.gclme.com/web-video/energy.mp4"></video>
            <!-- <video class="video" muted autoplay loop src="https://sass.gclme.com/web-video/energy.m4v"></video> -->
            <!-- <video class="video" muted autoplay loop :src="require('@/assets/mp4/xiaoxin.mp4')"></video>
            <img v-if="!isReading" class="img" :src="require('@/assets/mp4/xiaoxin.jpg')" alt=""> -->
            <!-- <video v-if="!isReading" class="video img" muted autoplay loop :src="require('@/assets/mp4/xiaoxin-1.mp4')"></video> -->
            <!-- <Human /> -->
        </div>
        <div class="video-bottom flex justify-content-between mt-80">
            <dv-border-box-8
                v-for="(item, i) in videoList"
                :key="'videoList' + i"
                class="item">
                <video class="video" muted autoplay loop :src="item.url"></video>
            </dv-border-box-8>
        </div>
    </div>
</template>

<script>
// import Human from '@/views/components/Human'
export default {
    components: {
        // Human
    },
    data() {
        return {
            videoList: [
                {
                    // url: `${require('@/assets/mp4/2.mp4')}`
                    url: 'https://sass.gclme.com/web-video/2.mp4'
                },
                {
                    // url: `${require('@/assets/mp4/3.mp4')}`
                    url: 'https://sass.gclme.com/web-video/3.mp4'
                },
                {
                    // url: `${require('@/assets/mp4/4.mp4')}`
                    url: 'https://sass.gclme.com/web-video/4.mp4'
                }
            ],
            isReading: false
        }
    },
    mounted() {
        this.$bus.$on('readingFn', (data) => {
            this.isReading = data.isReading
        })
    },
    destroyed() {
        this.$bus.$off('readingFn')
    }
}
</script>

<style lang="scss" scoped>
.monitor-1 {
    .video {
        width: 100%;
        height: 100%;
        
    }
    .video-top {
        height: 870px;
        // height: 1025px;
        position: relative;
        .img {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .video-bottom {
        > div {
            padding: 10px;
            width: 32%;
            height: 380px;
        }
    }
 }
</style>