<template>
    <div class="chonghuan-monitor flex justify-content-between">
        <!-- <MyMap /> -->
        <!-- <div class="cover cover-home"></div> -->
        <MyHeader />
        <Monitor1 class="monitor-part flexs" />
        <Monitor2 class="monitor-part flex1" />
    </div>
</template>

<script>
import MyHeader from '@/views/components/MyHeader.vue'
import Monitor1 from './components/monitor-1.vue'
import Monitor2 from './components/monitor-2.vue'
export default {
    components: {
        MyHeader,
        Monitor1,
        Monitor2
    },
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>
@import './components/chonghuan.scss';
.chonghuan-monitor {
    position: relative;
    height: 100%;
    background: url('~@/assets/images/chonghuan/bg-m.png') no-repeat;
    background-size: 100% 101%;
    .monitor-part {
        margin-top: $HeaderHeight;
    }
}
</style>